import { Typography } from '@mui/material'
import React from 'react';
import "./footer.scss";

const Footer = () => {
  return (
    <div  className='footer-wrapper'>
      <Typography className='footer-text'>&copy; 2024 Cyclonus Technologies. All rights reserved.</Typography>
    </div>
  )
}

export default Footer
