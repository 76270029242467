import { Typography } from "@mui/material";
import React from "react";
import org from "../../assets/images/org-mapping.png"
import analytics from "../../assets/images/analytics.png";
import revenue from "../../assets/images/revenue.png"
import tracking from "../../assets/images/tracking.png"
import "./offers.scss";

const Offers = () => {
  const offerItem = [
    {
      title: "Organization Structure",
      description:
        "Multiple user roles are mapped to build a hierarchical model where everyone contributes based on their responsibilities",
      imgURL: org,
    },
    {
      title: "Revenue Model",
      description:
        "Recruiter revenue is generated when joined candidates are billed based on the closure percentage, which then needs to be approved by peers",
      imgURL: revenue,
    },
    {
      title: "Candidate Tracking",
      description:
        "All candidates are processed and addressed, whether based on performance or other factors, ensuring that no profile is overlooked",
      imgURL: tracking,
    },
    {
      title: "Analytical Overview",
      description:
        "Eagle View, Quick View, and Interactive charts offer insights into team performance across closures, revenue, contributions, and more",
      imgURL: analytics,
    },
  ];
  return (
    <div className="offers-main-wrapper">
      <Typography className="offers-title">What we Offer</Typography>
      <div className="offer-items-main-block">
        {offerItem.map((item) => {
          return (
            <div className="offer-items-block">
              <div className="offers-first-section">
                <Typography className="item-title">{item.title}</Typography>
                <Typography className="item-description">
                  {item.description}
                </Typography>
              </div>
              <div className="offers-second-section">
                <img src={item.imgURL} alt="org" className="offers-img" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Offers;
