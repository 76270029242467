import { Box, Typography } from "@mui/material";
import React from "react";
import "./banner.scss";
import board from "../../assets/images/Board.png";
import boardImg from "../../assets/images/Board_Final.png";

const Banner = () => {
  return (
    <Box className="board-block-main-wrapper">
      <Typography className="board-title">How it Works</Typography>
      <div className="board-section">
        <img src={boardImg} alt="board" className="board-img" />
      </div>
    </Box>
  );
};

export default Banner;
