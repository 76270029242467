import React from 'react';
import "./youtubevideo.scss";
import { Typography } from '@mui/material';
const YouTubeEmbed = ({ videoId }) => {
  return (
    <div className='youtube-video-subblock'>
    <Typography className='youtube-title'>Challenge & Solution</Typography>
    <div className='youtube-main-wrapper' style={{ }}>
      <iframe
        src={`https://www.youtube.com/embed/${videoId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded YouTube Video"
        className='embed-videoframe'
      />
    </div>
    </div>
  );
};

export default YouTubeEmbed;