
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Header from "./components/header/Header";
import Banner from "./components/Banner/Banner";
import Plans from "./components/FeaturePlan/Plans";
import Offers from "./components/Offer/Offers";
import Contact from "./components/ContactUs/Contact";
import Footer from "./components/Footer/Footer";
import YouTubeEmbed from "./components/ChallengeAndSolutions/YoutubeVideo";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

const Home = () => (
  <>
    <div id="banner">
      <Banner />
    </div>
    <div id="offers">
      <Offers />
    </div>
    <div id="yotubeVideo" className="youtube-video-block"> 
      <YouTubeEmbed  videoId="ThLEZAwMr8s?si=8kG52gaLP5wZFuCN" />
    </div>
    <div id="plans">
      <Plans />
    </div>
    <div id="contact">
      <Contact />
    </div>
  </>
);


export default App;
