
import React from "react";
import { HashLink } from "react-router-hash-link";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./header.scss";
import funnel from "../../assets/images/Funnel.png";
import svgFunnel from "../../assets/images/mainfunnel.svg";
import rightFunnel from "../../assets/images/gf.png";
import Banner from "../Banner/Banner";
import backgroundImg from "../../assets/images/connect_transparent.png"

const drawerWidth = 240;
const navItems = [
  { name: "Home", path: "/#banner" },
  { name: "Offers", path: "/#offers" },
  { name: "Plans", path: "/#plans" },
  { name: "Contact", path: "/#contact" },
];

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Sourcer 2.0
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton
              component={HashLink}
              smooth
              to={item.path}
              sx={{ textAlign: "center" }}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" className="nav-wrapper">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            className="header-main-title"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            Sourcer 2.0
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                key={item.name}
                component={HashLink}
                smooth
                to={item.path}
                sx={{ color: "#fff" }}
              >
                {item.name}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }} className="box-main-wrapper">
        <Toolbar />
        {/* Add your main content here */}
        <div className="box-sub-wrapper">
          {/* <div className="banner-backgroundImmg-wrap"></div> */}
          <div className="banner-wrapper">
            <div className="banner-first-section">
              <Typography className="description">
                Smart solution for recruiters to bring in the best talent to
                your space
              </Typography>
            </div>
            <div className="banner-second-section">
              <img src={rightFunnel} alt="funnel" className="banner-img" />
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}

Header.propTypes = {
  window: PropTypes.func,
};

export default Header;
