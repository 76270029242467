import { Typography } from "@mui/material";
import React, { useState } from "react";
import "./plans.scss";

const Plans = () => {
  const [selectedOption, setSelectedOption] = useState(1);

  const options = [
    {
      id: 1,
      title: "ATS Plug-In",
      desc: "Exposing the Sourcer 2.0 extraction process as an API service, seamlessly integrated with your existing ATS tool for plug-and-play functionality",
    },
    {
      id: 2,
      title: "WhatsApp Integration",
      desc: "Sending real-time updates to candidates about their interview progress, with future plans to extend the service to capture their interest and decisions regarding job roles",
    },
    {
      id: 3,
      title: "Screenshot Extraction",
      desc: "Instead of extending support to multiple job portals based on architecture, the captured screenshot will extract candidate data and map it to predefined entities",
    },
    {
      id: 4,
      title: "Profile Recommendation",
      desc: "Based on the history of candidates hired for a job role and their CVs, the system will propose profiles for new job roles by matching them to the job descriptions (JD)",
    },
    {
      id: 5,
      title: "SSO & Async Process",
      desc: "Implementing SSO for seamless login to the app, with an asynchronous process during the extraction phase to handle retry logic and store data for future use",
    },
    {
      id: 6,
      title: "Smart Job Roles",
      desc: "Extending support to read from PDFs and other job posting websites to extract skill sets, and other relevant details in order to create system-required, structured job roles",
    },
  ];
  return (
    <div className="future-plan-main-container">
      <Typography className="plans-title">Future Plans</Typography>
      <div className="investment-desktop-container">
        {options.map((option) => (
          <div
            key={option.id}
            className={`investment-item ${
              selectedOption === option.id ? "active" : ""
            }`}
            onMouseEnter={() => setSelectedOption(option.id)}
          >
            <div
              className={`title-circle-block ${
                selectedOption === option.id ? "tab-active" : ""
              }`}
            >
              <span
                className={`circle ${
                  selectedOption === option.id ? "circle-hide" : ""
                }`}
              >{`0${option.id}`}</span>
              <p
                className={`title ${
                  selectedOption === option.id ? "bold" : ""
                }`}
              >
                {option.title}
              </p>
            </div>
            {selectedOption === option.id && (
              <p className="description">{option.desc}</p>
            )}
          </div>
        ))}
      </div>
      <div className="investment-mobile-container">
        {options.map((option) => (
          <div key={option.id} className={`investment-item`}>
            <div className={`title-circle-block`}>
            <div
                className={`circle ${
                  selectedOption === option.id ? "circle-hide" : ""
                }`}
              >{`0${option.id}`}</div>
              <p className={`title`}>{option.title}</p>
            </div>
            <p className="description">{option.desc}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Plans;
