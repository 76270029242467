import { TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import "./contact.scss";

const Contact = () => {
  const [subject, setSubject] = useState("Let's collaborate");
  const [mail, setMail] = useState("Hi Sourcer 2.0,");

  const contactHandler = (e) => {
    e.preventDefault();

    const header = encodeURIComponent(`${subject}`);
    const body = encodeURIComponent(`${mail}`);

    const mailto = `mailto:founder@cyclonus.in?subject=${header}&body=${body}`;

    window.location.href = mailto;
  };

  return (
    <div className="contact-main-wrapper">
      <Typography className="contact-title">Want to Know More</Typography>
      <div className="contact-form-wrapper">
        <form
          action="/send-message"
          method="post"
          onSubmit={contactHandler}
          className="form-content"
        >
          <TextField
            required
            label="Subject"
            defaultValue={subject}
            onChange={(e) => setSubject(e.target.value)}
            className="concern-msg"
            placeholder="Enter Subject"
            size="small"
          />
          <TextField
            required
            label="Your Message"
            defaultValue={mail}
            onChange={(e) => setMail(e.target.value)}
            className="concern-msg"
            placeholder="Type your message"
            size="small"
            focused
            multiline
            rows={5}
          />
          <div className="contact-btn-wrapper">
            <button type="submit" className="contact-btn">
              Contact Us
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Contact;
